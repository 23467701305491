import React, { useEffect, useState } from 'react';
import { useDispatch,  useSelector } from 'react-redux';
import {useHistory} from 'react-router-dom';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Avatar  from '@mui/material/Avatar';
import Chip  from '@mui/material/Chip';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';


import {page} from '../../styles/main';
import { requirePropFactory } from '@mui/material';

import { getData } from '../../actions/instrument';


const classes = {
    root:{
        marginTop:10
    }
}

const styles = {
    media: {
      height: '100%',
      paddingTop: '75%', // 16:9,
      marginTop:'30'
    },
};






export default function Main(){
    const history = useHistory();
    const dispatch = useDispatch();
    const instrument = useSelector(state => state.instrument.data);
    const [counter, setCounter] = useState(0)

    useEffect(() => {
        dispatch(getData())
    }, [counter == 0])


    useEffect(() => {
        const interval = setInterval(() => {
          if(counter < 60){
            setCounter(counter + 1);
          }else{
            setCounter(0)
          }
         
        }, 1000);
    
        return () => {
          clearInterval(interval);
        };
      });

    return(
      <div>
      <Grid style={{height:"100vh"}} justifyContent="center" alignItems="center" container direction="row">
        <Grid item xs={12} lg={12} sm={12} md={12} style={{marginTop:0, padding:10,}}>
           
                <div style={{display:"flex", flexDirection:"column", justifyContent:"center", marginTop:20, marginBottom:20}}>
                <Typography align="center" variant="h6" color="textSecondary"> STOPWATCH </Typography>
                <Typography style={{fontFamily:"Space Grotesk"}} align="center" variant="h2"> {counter} </Typography>
                </div>
               

                <Grid container direction="row">
                    <Grid item md={4} lg={4} sm={4}  xs={12}>
                        <Display data={instrument.station1} station={"Plant One"}  />
                    </Grid>
                    <Grid item md={4} lg={4} sm={4} xs={12}>
                        <Display data={instrument.station2} station={"Plant Two"}  />
                    </Grid>
                    <Grid item md={4} lg={4} sm={4} xs={12}>
                        <Display data={instrument.station3} station={"Plant Three"}  />
                    </Grid>
                    <Grid item md={4} lg={4} sm={4} xs={12}>
                        <Display data={instrument.station4} station={"Plant Four"}  />
                    </Grid>
                    <Grid item md={4} lg={4} sm={4} xs={12}>
                        <Display data={instrument.station5} station={"Plant Five"}  />
                    </Grid>
                    <Grid item md={4} lg={4} sm={4} xs={12}>
                        <Display data={instrument.station6} station={"Plant Six"}  />
                    </Grid>
                </Grid>

                
        </Grid>
      </Grid>
           
        </div>
    )
}
function Display(props){
    const [data, setData] = useState(props.data ? props.data : [])

    useEffect(() => {
      setData(props.data ? props.data: []);
    }, [props.data])
    return(
        <>
        
        <Typography align="center" variant="h6" color="textSecondary"> {props.station.toUpperCase()} </Typography>
        <Grid direction="row" justifyContent="center" container>
        {data.length > 0 ? <Chip style={{borderRadius:0, padding:0}} 
        color={data[data.length - 1].status == "online" ? "success": "warning"} 
        label={<Typography variant="body1"> {data.length > 0 ? data[data.length - 1].status : "offline"} </Typography>} /> : 
        <Chip style={{borderRadius:0, padding:0}} color={"warning"} 
        label={<Typography variant="body1"> {"offline"} </Typography>} />}
        </Grid>
        <Typography align="center" style={{fontFamily:"Space Grotesk"}} variant="h1">
             {data.length > 0 ? parseFloat(data[data.length - 1].value).toFixed(2) : 0} &#xb0;C </Typography>
        </>
    )
}