import React, {useEffect} from 'react';
import {BrowserRouter as Router, Switch, Route, Link, BrowserRouter} from "react-router-dom";
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

import logo from './logo.svg';
import history from './history';
import './App.css';
import { useDispatch, useSelector, useStore } from "react-redux";
import { Backdrop, Button, CircularProgress} from '@mui/material';
import {globalStyles} from './styles/global.js';
//Account


import Notify from './components/page/Notify';
import Main from './screens/main/Main';
import {THEME} from './styles/theme.js'





function App() {
  const loading = useSelector(state => state.app.loading);
  const dispatch = useDispatch();

  useEffect(() => {
  }, []);
  
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={THEME}>    
       <Router history={history}> 
           <Notify />
           
           <Switch>
             <Route exact path="/" component={Main} />
           </Switch>
       
         </Router>
       </ThemeProvider>
       </StyledEngineProvider>
  );
}

export default App;
